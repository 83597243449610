










































































































































































































































































import { Messages } from "@/models/enums/messages.enum";
import Vue from "vue";
import moment from "moment";
import { assetsServices } from "@/services/assets.service";
import { debounce, debounceProcess } from "@/helpers/debounce";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { Mode } from "@/models/enums/global.enum";
import {
  currencyFormat,
  changeCurrencytoNumeric,
  numericOnlyv2,
  numericOnly,
} from "@/validator/globalvalidator";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
export default Vue.extend({
  data() {
    this.getListAssetBook = debounceProcess(this.getListAssetBook, 200);
    return {
      DEFAULT_DATE_FORMAT,
      formatCurrency: currencyFormat,
      formatCurrencytoNumber: changeCurrencytoNumeric,
      formatNumericOnly: numericOnly,
      mode: "" as string,
      form: this.$form.createForm(this, { name: "openCost" }),
      loadingListAssetMaster: false as boolean,
      isFormSubmitted: false as boolean,
      disabledType: true as boolean,
      idReferenceNumber: "" as string,
      unitValidate: false as boolean,
      retireValidate: false as boolean,
      sellingValidate: false as boolean,
      requiredRetired: false as boolean,
      requiredSale: false as boolean,
      requiredRemoval: false as boolean,
      requiredCostOfSold: false as boolean,
      requiredNetBookValue: false as boolean,
      disabledCostOfRemoval: true as boolean,
      disabledNetBookValue: true as boolean,
      dataListBook: [] as any[],
      loadingListAssetBook: false as boolean,
      valueNbv: 0 as number,
      formRules: {
        assetId: {
          label: "Asset Number",
          name: "assetId",
          placeholder: "Insert Asset Number",
          decorator: [
            "assetId",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        retireType: {
          label: "Retire Type",
          name: "retireType",
          placeholder: "Insert Retire Type",
          decorator: [
            "retireType",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        assetBookId: {
          label: "Book",
          name: "assetBookId",
          placeholder: "Insert Book",
          decorator: [
            "assetBookId",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        unit: {
          label: "Quantity",
          name: "unit",
          placeholder: "Insert Unit",
          decorator: [
            "unit",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        unitRetired: {
          label: "Unit Retired",
          name: "unitRetired",
          placeholder: "Insert Unit Retired",
          decorator: [
            "unitRetired",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        invoiceNumber: {
          label: "Invoice Number (If Sold)",
          name: "invoiceNumber",
          placeholder: "Insert Invoice Number",
          decorator: [
            "invoiceNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        retireDate: {
          label: "Retire Date",
          name: "retireDate",
          placeholder: "Insert Retire Date",
          decorator: [
            "retireDate",
            {
              initialValue: moment(),
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        currentCost: {
          label: "Current Cost",
          name: "currentCost",
          placeholder: "Insert Current Cost",
          decorator: [
            "currentCost",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        costOfRetired: {
          label: "Cost Of Disposal",
          name: "costOfRetired",
          placeholder: "Insert Cost Of Disposal",
          decorator: [
            "costOfRetired",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        costOfSale: {
          label: "Selling Price",
          name: "costOfSale",
          placeholder: "Insert Selling Price",
          decorator: [
            "costOfSale",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: numericOnlyv2,
                },
              ],
            },
          ],
        },
        costOfRemoval: {
          label: "Cost of Sold",
          name: "costOfRemoval",
          placeholder: "Insert Cost of Sold",
          decorator: [
            "costOfRemoval",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        gainLossAmount: {
          label: "Gain Loss Amount",
          name: "gainLossAmount",
          placeholder: "Insert Gain Loss Amount",
          decorator: [
            "gainLossAmount",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        netBookValue: {
          label: "Net Book Value",
          name: "netBookValue",
          placeholder: "Insert Net Book Value",
          decorator: [
            "netBookValue",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        soldTo: {
          label: "Sold To",
          name: "soldTo",
          placeholder: "Insert Sold To",
          decorator: [
            "soldTo",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
    };
  },
  created() {
    this.mode = this.$route.meta.mode;
    this.requiredCostOfSold = true;
  },
  mounted() {
    switch (this.mode) {
      case Mode.EDIT:
        this.getDetailData();
        this.getListAssetBook("");
        break;
      case Mode.VIEW:
        this.idReferenceNumber = decodeURIComponent(this.$route.params.id);
        this.getDataViewRetirement();
        this.getListAssetBook("");
        break;
      default:
        break;
    }
  },
  methods: {
    moment,
    onBlur(value, form) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as any;
        dataobj[form] = this.formatCurrency(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    onFocus(value, form) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as any;
        dataobj[form] = this.formatCurrencytoNumber(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    getDataViewRetirement() {
      const paramsRetirement = {
        page: 0,
        limit: 10,
        search: `documentNumber~${this.idReferenceNumber}`,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      assetsServices.detailAssetRetirement("", paramsRetirement).then(data => {
        this.form.setFieldsValue({
          currentCost: this.formatCurrency(data.data[0].currentCost),
          assetId: data.data[0].assetNo,
          assetBookId: data.data[0].assetBookId,
          unit: data.data[0].unit,
          costOfSale: this.formatCurrency(data.data[0].costOfSale)
            ? this.formatCurrency(data.data[0].costOfSale)
            : 0,
          unitRetired: data.data[0].unitRetired,
          soldTo: data.data[0].soldTo,
          retireType: data.data[0].retireType,
          retireDate: data.data[0]?.retireDate
            ? moment(data.data[0].retireDate)
            : null,
          invoiceNumber: data.data[0].invoiceNumber,
          gainLossAmount: this.formatCurrency(data.data[0].gainLossAmount)
            ? this.formatCurrency(data.data[0].gainLossAmount)
            : 0,
          costOfRetired: this.formatCurrency(data.data[0].costOfRetired)
            ? this.formatCurrency(data.data[0].costOfRetired)
            : 0,
          costOfRemoval: this.formatCurrency(data.data[0].costOfRemoval)
            ? this.formatCurrency(data.data[0].costOfRemoval)
            : 0,
        });
        this.dataListBook.push({
          id: data.assetBook.id,
          name: data.assetBook.name,
        });
      });
    },
    cancleHandle(): void {
      this.form.resetFields();
      this.$router.push("/inquiry/find");
    },
    getListAssetBook(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        search: `type~commercial_AND_active~true`,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
      this.loadingListAssetBook = true;
      assetsServices
        .listAssetBook(params)
        .then(data => {
          this.dataListBook = data.data;
        })
        .finally(() => (this.loadingListAssetBook = false));
    },
    getDetailData(): void {
      assetsServices
        .listMasterAssetById({}, this.$route.params.id)
        .then((data: any) => {
          this.valueNbv = data.netBookValue;
          this.form.setFieldsValue({
            currentCost: this.formatCurrency(data.originalCost)
              ? this.formatCurrency(data.originalCost)
              : 0,
            netBookValue: this.formatCurrency(data.netBookValue)
              ? this.formatCurrency(data.netBookValue)
              : 0,
            assetId: data.assetNo,
            assetBookId: data.assetBook.id,
            unit: data.unit,
            costOfSale: "0",
          });
          this.dataListBook.push({
            id: data.assetBook.id,
            name: data.assetBook.name,
          });
        })
        .finally();
    },

    validateInput($event, type, _value): void {
      if (type === "retire") {
        if ($event <= this.form.getFieldValue("unit")) {
          if (type === "retire" && $event) {
            this.retireValidate = true;
          } else {
            this.retireValidate = false;
          }
          if (this.retireValidate) this.disabledType = false;
          else {
            this.disabledType = true;
            this.form.setFieldsValue({
              retireType: null,
            });
          }
        } else {
          this.disabledType = true;
          this.form.setFieldsValue({
            retireType: null,
          });
          this.$notification["error"]({
            message: "Error",
            description: "Unit Retired cannot exceed Unit",
          });
        }
      } else {
        if (
          this.form.getFieldValue("retireType") &&
          this.form.getFieldValue("retireType") === "SOLD"
        ) {
          this.form.setFieldsValue({
            gainLossAmount: this.formatCurrency(
              Number(this.form.getFieldValue("costOfSale")) -
                (this.valueNbv / this.form.getFieldValue("unit")) *
                  this.form.getFieldValue("unitRetired")
            ),
          });
        }
      }
    },
    customValidator(_rule, value, callback) {
      if (value > this.form.getFieldValue("unit")) {
        this.disabledType = true;
        this.form.setFieldsValue({
          retireType: null,
        });
        callback("Unit Retired cannot exceed Unit");
      }
    },
    typeChange(value: string): void {
      switch (value) {
        case "DISPOSAL":
          this.form.setFieldsValue({
            costOfRetired: this.formatCurrency(
              (Number(
                this.formatCurrencytoNumber(
                  this.form.getFieldValue("currentCost")
                )
              ) /
                this.form.getFieldValue("unit")) *
                this.form.getFieldValue("unitRetired")
            ),
            costOfRemoval: "",
            costOfSale: "0",
          });
          this.form.setFieldsValue({
            gainLossAmount: "",
          });
          this.requiredRetired = true;
          this.requiredRemoval = false;
          this.requiredSale = false;
          this.disabledCostOfRemoval = true;
          this.requiredCostOfSold = true;
          break;
        case "SOLD":
          this.form.setFieldsValue({
            costOfRetired: "",
            costOfRemoval: this.formatCurrency(
              (Number(
                this.formatCurrencytoNumber(
                  this.form.getFieldValue("currentCost")
                )
              ) /
                this.form.getFieldValue("unit")) *
                this.form.getFieldValue("unitRetired")
            ),
          });
          this.form.setFieldsValue({
            gainLossAmount: this.formatCurrency(
              Number(this.form.getFieldValue("costOfSale")) -
                (this.valueNbv / this.form.getFieldValue("unit")) *
                  this.form.getFieldValue("unitRetired")
            ),
          });
          this.disabledCostOfRemoval = true;
          this.requiredRetired = false;
          this.requiredRemoval = false;
          this.requiredSale = false;
          this.requiredCostOfSold = false;
          break;
        default:
          break;
      }
    },
    submitForm(): void {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.retireDate = values.retireDate.format();
          Object.keys(values).forEach(key => {
            if (values[key] === "" || values[key] === undefined) {
              delete values[key];
            }
          });
          values["currentCost"] = this.formatCurrencytoNumber(
            values["currentCost"]
          );
          values["costOfSale"] = this.formatCurrencytoNumber(
            values["costOfSale"]
          );
          if (Object.values(values).indexOf("DISPOSAL") > -1) {
            values["costOfRetired"] = this.formatCurrencytoNumber(
              values["costOfRetired"]
            );
          } else {
            values["costOfRemoval"] = this.formatCurrencytoNumber(
              values["costOfRemoval"]
            );
            values["gainLossAmount"] = this.formatCurrencytoNumber(
              values["gainLossAmount"]
            );
          }
          this.isFormSubmitted = true;
          assetsServices
            .createAssetRetirement(values)
            .then(() => {
              this.form.resetFields();
              this.$notification.success({
                description: Messages.CREATE_SUCCESS,
                message: "Success",
                duration: 30,
              });

              this.$router.push("/inquiry/find");
            })
            .catch(error => {
              this.$notification.error({
                description: Messages.CREATE_FAIL,
                message: "Error",
                duration: 30,
              });
              this.$notification.error({
                description: error.details,
                message: "Error",
                duration: 30,
              });

              this.isFormSubmitted = false;
            })
            .finally(() => (this.isFormSubmitted = false));
        } else {
          this.$notification["error"]({
            message: "Error",
            description: "Form is mandatory",
          });
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 10 },
        wrapperCol: { span: 12 },
      };
    },
  },
});
